import { useRef } from 'react'
import { useClickAway } from 'react-use'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import classNames from 'classnames'

import HealthStatusBar from '@/components/shared/Navbar/Dropdown/HealthStatusBar'
import TopicBar from '@/components/shared/Navbar/Dropdown/TopicBar'
import { SidebarType } from '@/components/shared/Navbar/Dropdown'
import CloseIcon from '@/components/shared/CloseIcon'

interface ToggleSidebarProps {
  type: SidebarType
  onOutsideClick: () => void
}

const ToggleSidebar = ({ type, onOutsideClick }: ToggleSidebarProps) => {
  const ref = useRef(null)
  useClickAway(ref, onOutsideClick)
  const { isTablet } = useBreakpoints()
  const contentClassName = classNames(
    'flex flex-row',
    { 'space-x-5': isTablet },
    { 'space-x-30': !isTablet }
  )
  const topicBarTopicsClass = classNames(
    'flex',
    { 'flex-col space-y-12': isTablet },
    { 'flex-row space-x-[148px]': !isTablet }
  )
  const splitTopicBarSeries = isTablet ? 5 : 4
  return (
    <div className="relative w-full h-full" ref={ref}>
      {type === 'healthstatus' && (
        <HealthStatusBar
          split={9}
          className="flex overflow-y-scroll flex-col px-12 py-8 space-y-12"
          contentClassName={contentClassName}
        >
          <HealthStatusBar.Header headerClassName="heading-4 lg:heading-3" />
        </HealthStatusBar>
      )}
      {type === 'topic' && (
        <TopicBar className="overflow-y-scroll px-12 py-8">
          <div className="flex flex-col space-y-12">
            <TopicBar.Series
              seriesSplit={splitTopicBarSeries}
              seriesBoxClassName="flex flex-col space-y-1"
              seriesColumnClassName="flex flex-col space-y-3"
            />
            <div className={topicBarTopicsClass}>
              <TopicBar.MainTopic topicClassName="flex flex-col space-y-3" />
              <TopicBar.OtherTopics />
            </div>
          </div>
        </TopicBar>
      )}
      <span className="absolute top-0 right-0 p-5">
        <CloseIcon
          className="w-8 h-8 cursor-pointer lg:w-12 lg:h-12 stroke-white"
          onCloseFinished={onOutsideClick}
        />
      </span>
    </div>
  )
}

export default ToggleSidebar
