import { useRef } from 'react'
import { ArrowRight } from '@vhl/icons'
import { motion } from 'framer-motion'

interface WithArrowHoverProps {
  children?: React.ReactNode
  className?: string
  height?: number | string
  width?: number | string
  fill?: string
  stroke?: string
}

const WithArrowHover = ({
  children,
  className = 'flex flex-row space-x-[4px] cursor-pointer',
  height,
  width,
  fill,
  stroke,
}: WithArrowHoverProps) => {
  const ref = useRef(null)
  const variants = {
    hover: { x: 0, opacity: 1 },
  }
  return (
    <motion.div
      className={`${className}`}
      ref={ref}
      whileHover="hover"
      whileTap="hover"
    >
      {children}
      <motion.span initial={{ x: -5, opacity: 0 }} variants={variants}>
        <ArrowRight
          height={height}
          width={width}
          className={`${stroke || 'stroke-white'}`}
        />
      </motion.span>
    </motion.div>
  )
}

export default WithArrowHover
