import { HEALTH_STATUS } from '@/constants'
import { getCategoryTypeUrl, slugify, splitArrayToChunks } from '@/utils'
import WithArrowHover from '@/components/shared/WithArrowHover'
import Divider from './Divider'
import { Link } from 'gatsby'

interface HealthStatusBarProps {
  split?: number
  className?: string
  contentClassName?: string
  children?: React.ReactNode
}

interface HealthStatusBarHeaderProps {
  className?: string
  headerClassName?: string
  captionClassName?: string
}

function HealthStatusTitle({ title }: { title: string }) {
  return (
    <WithArrowHover
      className="flex items-center justify-start space-x-2 cursor-pointer"
      height={24}
      width={24}
    >
      <Link
        className="heading-6"
        to={`/${getCategoryTypeUrl('health-status')}/${slugify(title)}`}
      >
        {title}
      </Link>
    </WithArrowHover>
  )
}

function Header({
  className = 'flex flex-col space-y-2',
  headerClassName = 'heading-3',
  captionClassName = 'caption',
}: HealthStatusBarHeaderProps) {
  return (
    <div className={className}>
      <h1 className={headerClassName}>Tình trạng sức khỏe</h1>
      <Divider />
      <p className={captionClassName}>
        Giải mã các tình trạng bệnh lý bạn đang gặp phải
      </p>
    </div>
  )
}

const HealthStatusBar = ({
  split = 2,
  className,
  contentClassName = 'flex flex-row space-x-[120px]',
  children,
}: HealthStatusBarProps) => {
  const contentChunks = splitArrayToChunks(HEALTH_STATUS, split)
  const healthStatusBarClassName = `bg-blue-500 text-white w-full h-full ${className}`
  return (
    <div className={healthStatusBarClassName}>
      {children}
      <div className={contentClassName}>
        {contentChunks.map((chunk, index) => (
          <div key={index} className="flex flex-col space-y-4">
            {chunk.map((title, index) => (
              <HealthStatusTitle key={index} title={title} />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

HealthStatusBar.Header = Header

export default HealthStatusBar
