import { Link } from 'gatsby'
import WithArrowHover from '@/components/shared/WithArrowHover'
import { TOPIC, CATEGORIES } from '@/constants'
import { getCategoryTypeUrl, splitArrayToChunks } from '@/utils'
import { slugify } from '@/utils'
import Divider from './Divider'

function MainTopic({
  className = 'flex flex-col space-y-4',
  topicClassName = 'flex flex-col space-y-4',
}: {
  className?: string
  topicClassName?: string
}) {
  return (
    <div className={className}>
      <div className="flex flex-col">
        <h1 className="mb-2 heading-4">Chủ đề</h1>
        <Divider />
      </div>
      <div className={topicClassName}>
        {TOPIC.map((topic, index) => (
          <WithArrowHover
            key={index}
            className="flex space-x-2 cursor-pointer"
            width={24}
            height={24}
          >
            <Link
              className="heading-6"
              to={`/${getCategoryTypeUrl('topic')}/${slugify(topic)}`}
            >
              {topic}
            </Link>
          </WithArrowHover>
        ))}
      </div>
    </div>
  )
}

function OtherTopics() {
  const topics = [{ title: 'Podcast', link: '/podcast' }]
  return (
    <div className="flex flex-col space-y-4">
      {topics.map((topic, index) => (
        <Link key={index} to={topic.link} className="flex flex-col space-y-2">
          <WithArrowHover width={32} height={32}>
            <h4 className="heading-4">{topic.title}</h4>
          </WithArrowHover>
          <Divider />
        </Link>
      ))}
    </div>
  )
}

function Series({
  seriesSplit = 3,
  seriesBoxClassName = 'flex flex-col space-y-2',
  seriesRowClassName = 'flex flex-row space-x-12',
  seriesColumnClassName = 'flex flex-col space-y-4',
}: {
  seriesSplit?: number
  seriesBoxClassName?: string
  seriesRowClassName?: string
  seriesColumnClassName?: string
}) {
  const seriesColumns = splitArrayToChunks(CATEGORIES, seriesSplit)
  function SeriesBox({
    title,
    description,
  }: {
    title: string
    description: string
  }) {
    return (
      <Link
        to={`/${getCategoryTypeUrl('series')}/${slugify(title)}`}
        className={seriesBoxClassName}
      >
        <WithArrowHover width={24} height={24}>
          <h5 className="heading-6">{title}</h5>
        </WithArrowHover>
        <p className="caption-sm">{description}</p>
      </Link>
    )
  }
  return (
    <div className="flex flex-col space-y-4">
      <div>
        <h1 className="mb-2 heading-4">Series</h1>
        <Divider />
      </div>
      <div className={seriesRowClassName}>
        {seriesColumns.map((seriesColumn, index) => (
          <div key={index} className={seriesColumnClassName}>
            {seriesColumn.map((series, index) => (
              <SeriesBox
                key={index}
                title={series.title}
                description={series.description}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

function Header({ heading = 'heading-3' }: { heading?: string }) {
  return (
    <div className="flex flex-col space-y-2">
      <h3 className={heading}>Chuyên mục</h3>
    </div>
  )
}

interface TopicBarProps {
  className?: string
  children?: React.ReactNode
}

// Topic Bar Component

const TopicBar = ({ children, className }: TopicBarProps) => {
  const topicBarClassname = `w-full h-full bg-purple-600 text-white ${className}`
  return <div className={topicBarClassname}>{children}</div>
}

TopicBar.Header = Header
TopicBar.Series = Series
TopicBar.MainTopic = MainTopic
TopicBar.OtherTopics = OtherTopics

export default TopicBar
